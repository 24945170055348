<template>
  <b-modal id="modal-center"
           v-model="visible"
           ok-variant="primary"
           ok-title="Submit"
           :ok-disabled="(count < 1 && !current.scheduleNoColumns) || selectedDate === null"
           cancel-variant="link"
           centered
           :title="title"
           :no-close-on-backdrop="true"
           @ok="onOk"
           @cancel="onCancel"
           @close="onClose"
  >
    <b-container fluid>
      <div class="row align-items-center mb-3" v-if="currentEntity !== null">
        <label class="col-3 mb-0 pr-0">{{ current !== null ? current.entityType : null }}:</label>
        <div class="col">
          {{ currentEntity.name }}
        </div>
      </div>
      <div class="row align-items-center mb-3">
        <label class="col-3 mb-0 pr-0">{{ current !== null ? current.entitySubType : null }}:</label>
        <div class="col">
          {{ entityNames }}
        </div>
      </div>
      <div class="row align-items-top mb-3" v-if="!current.scheduleNoColumns">
        <label class="col-3 mb-0 pr-0 mt-2">{{ current !== null ? current.regionOrLanguage : null }}:</label>
        <div class="col">
          <b-form-tags v-model="selectedRegions" size="lg" add-on-change no-outer-focus class="mb-2">
            <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
              <b-form-select
                v-bind="inputAttrs"
                v-on="inputHandlers"
                :disabled="disabled || availableRegions.length === 0"
                :options="availableRegions"
                v-if="(region === null || region === undefined || selectedRegions.length === 0) && allowRegionSelection"
              >
                <template v-slot:first>
                  <option disabled value="">Choose a {{ lowercase(current.regionOrLanguage) }}...</option>
                </template>

                <!-- <b-form-select-option v-for="region in availableRegions" :key="region.id" :value="region">{{ region.name }}</b-form-select-option> -->
              </b-form-select>
              <ul class="list-inline d-inline-block my-2">
                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                  <b-form-tag
                    @remove="removeTag(tag)"
                    :title="tag"
                    variant="primary"
                    :disabled="!allowRegionSelection"
                  >
                    {{ tag }}
                  </b-form-tag>
                </li>
              </ul>
            </template>
          </b-form-tags>
        </div>
      </div>
      <div class="row align-items-center mb-3">
        <label class="col-3 mb-0 pr-0">Valuation Date:</label>
        <div class="col">
          <b-form-select v-model="selectedDate">
            <b-form-select-option v-for="date in dates" :key="date.valuationDate" :value="date">{{ date.displayDate | date(current.dateFormat || 'dd-MM-yyyy') }}</b-form-select-option>
          </b-form-select>
        </div>
      </div>
      <div class="row align-items-center mb-3">
        <label class="col-3 mb-0 pr-0">Use Approval Workflow:</label>
        <div class="col">
          <b-checkbox v-model="useApprovalWorkflow" />
        </div>
      </div>
      <div class="row align-items-center mb-3" v-if="standardDistributionVisible">
        <label class="col-3 mb-0 pr-0">Use Standard Distribution:</label>
        <div class="col">
          <b-checkbox v-model="useStandardDistribution" />
        </div>
      </div>
    </b-container>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { EntitySchedule, ScheduleDate, EntityScheduleEntry, EntityScheduleRegion } from '../../store/schedule/state';
import { lowercase, camelcase } from '@/utilities/text.utils';
import { Configuration, ConfigurationEntity } from '../../store/configuration/state';

const configurationModule = namespace('configuration');
const scheduleModule = namespace('schedule');

@Component({
  components: {
  },
})
export default class RunScheduleDialog extends Vue {
  @configurationModule.Getter private current!: Configuration;
  @configurationModule.Getter public currentEntity!: ConfigurationEntity | null;
  @scheduleModule.Getter public entitySchedule!: EntitySchedule | null;
  @scheduleModule.Getter private regions!: Array<EntityScheduleRegion>;
  @configurationModule.Getter private dates!: Array<ScheduleDate>;
  @configurationModule.Getter private currentDate!: ScheduleDate | null;

  @Prop({ type: Boolean, required: true }) public show!: boolean;
  @Prop({ required: true }) public value!: Array<EntityScheduleEntry>;
  @Prop({ required: false, default: null }) public region!: EntityScheduleRegion | null;
  @Prop({ required: false, default: true }) public allowRegionSelection!: boolean;
  @Prop({ type: String, required: true, default: 'All' }) public selectionMode!: 'SingleRegion' | 'SingleEntity' | 'Single' | 'All';

  lowercase = lowercase;

  private visible: boolean = false;
  private selectedRegions: Array<string> = [];
  private useApprovalWorkflow: boolean = true;
  private useStandardDistribution: boolean = true;
  private standardDistributionVisible: boolean = true;

  public mounted(): void {
    if (this.currentDate === null) {
      this.$store.commit('configuration/setCurrentDate', this.current.dates[0]?.valuationDate || null);
    }
  }

  private get title(): string {
    if (this.value.length < 1) {
      return 'Request a report';
    }

    const regions = this.region !== null && this.region !== undefined ? 1 : this.selectedRegions.length;

    if (this.value.length * regions === 1) {
      return 'Request 1 report';
    }

    return `Request ${this.count} reports`;
  }

  private get count(): number {
    // NOTE(Dan): If we are selecting multiple sub-entities or running the whole entity
    //            then we should only request the active items.
    //
    //            Otherwise, we're only selecting a single sub-entity, so should be able
    //            to select additional regions to be run.
    const selected = this.value.flatMap((e) => {
      return Object.keys(e.regions).filter((k) => {
        return (this.value.length === 1 || e.regions[k]) && this.selectedRegions.some((r) => camelcase(r) === k);
      });
    });

    return selected.length;
  }

  private get selectedDate(): ScheduleDate | null {
    return this.currentDate;
  }

  private set selectedDate(date: ScheduleDate | null) {
    this.$store.commit('configuration/setCurrentDate', date?.valuationDate || null);
  }

  private get availableRegions(): Array<string> {
    return this.regions.filter((r) => !this.selectedRegions.some((s) => s === r.name)).map((r) => r.name);
  }

  private get entityNames(): string {
    if (this.entitySchedule!.entities.filter((e) => e.active || e.subActive).length === this.value.length && this.value.length > 5) {
      return 'All';
    }

    return this.value.map((i) => i.name).join(', ');
  }

  public get subEntities(): Array<EntityScheduleEntry> {
    if (this.entitySchedule === null) {
      return [];
    }

    return this.entitySchedule.entities;
  }

  private onOk(): void {
    const entities = this.value;
    const regions = this.regions.filter((r) => this.selectedRegions.some((s) => s === r.name));
    this.$emit('ok', { entities: entities, regions: regions, valuationDate: this.selectedDate!.valuationDate, useApprovalWorkflow: this.useApprovalWorkflow, useStandardDistribution: this.useStandardDistribution });
  }

  private onCancel(): void {
    this.$emit('cancel');
  }

  private onClose(): void {
    this.$emit('close');
  }

  @Watch('value')
  private onItemsChanged(value: Array<EntityScheduleEntry>): void {
    this.selectedRegions = [];
    this.useApprovalWorkflow = true;
    this.useStandardDistribution = true;
    this.standardDistributionVisible = true;

    if (value.length < 1) {
      return;
    }

    if (this.region !== null && this.region !== undefined) {
      this.selectedRegions = [this.region.name];

      if (value.length === 1) {
        const enabled = value[0].regions[camelcase(this.region.name)];
        if (enabled === null || enabled === false) {
          this.useStandardDistribution = false;
          this.standardDistributionVisible = false;
        }
      }

      return;
    }

    const selected = value.flatMap((e) => {
      return Object.keys(e.regions).filter((k) => {
        return e.regions[k];
      });
    });

    this.selectedRegions = this.availableRegions.filter((r) => {
      return selected.some((s) => s === camelcase(r));
    });
  }

  @Watch('selectedRegions')
  private onSelectedRegionsChanged(value: Array<string>): void {
    if (this.region === null || !this.allowRegionSelection || value.length < 1) {
      return;
    }

    this.$emit('region-changed', [...value]);
  }

  @Watch('show', { immediate: true })
  private onShowChanged(value: boolean): void {
    this.visible = value;
  }
}
</script>
