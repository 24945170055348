<template>
  <div class="help-videos" :aria-busy="loading">
    <b-table class="help-video-table"
             ref="table"
             striped
             hover
             selectable
             :sticky-header="headerStyle"
             show-empty
             select-mode="single"
             sort-icon-left
             :fields="fields"
             :items="files"
             :busy="loading"
    >
      <template v-slot:cell(name)="{ item }">
        {{ getFileNameWithoutExtension(item.name) }}
      </template>
      <template v-slot:cell(fileSize)="{ item }">
        <file-size :bytes="item.fileSize" unit="mb" />
      </template>
      <template v-slot:cell(actions)="{ item }">
        <b-button-group>
          <b-button size="sm" variant="outline-primary" :title="'Download ' + item.name" @click="onDownloadClicked(item)">
            <b-icon icon="download" aria-hidden="true"></b-icon>
          </b-button>
        </b-button-group>
      </template>
    </b-table>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import FileSize from '@/components/utils/file-size.vue';
import { namespace } from 'vuex-class';
import { HelpVideo } from '../../store/help/state';
import { FileUtils } from '@/utilities/file.utils';

const environmentModule = namespace('environment');
const helpModule = namespace('help');

@Component({
  components: {
    FileSize,
  },
})
export default class HelpVideoTable extends Mixins(BvToastMixin) {
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @environmentModule.Getter('current') environment!: { environment: string, version: string | null, runtime: string } | null;
  @helpModule.Getter files!: Array<HelpVideo>;

  get fields(): Array<{ key: string, label: string }> {
    const fields: Array<{ key: string, label: string, class?: Array<string> | string, tdClass?: Array<string> | string, thClass?: Array<string> | string, sortable?: boolean, formatter?: Function, sortByFormatted?: boolean | Function }> = [
      { key: 'name', label: 'Video', class: ['col-auto'] },
      { key: 'fileSize', label: 'Size', class: ['w-75'] },
      { key: 'actions', label: '', tdClass: ['text-right'], class: ['col-auto'] }
    ];

    return fields;
  }

  get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 230px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 230px)';
    }

    return 'calc(100vh - 254px)';
  }

  async onDownloadClicked(item: HelpVideo): Promise<void> {
    try {
      const data = await this.$store.dispatch('help/downloadHelpVideoAsync', item.name) as Blob;
      FileUtils.downloadFile(data, item.name!);
    } catch (e) {
      this.showErrorToast('Could not download file. Please try again.');
    }
  }

  getFileNameWithoutExtension(fileName: string) {
    const index = fileName.lastIndexOf('.');
    if (index > -1) {
      fileName = fileName.substring(0, index);
    }

    return fileName;
  }
}
</script>
