<template>
  <b-table class="support-tickets-table"
           ref="table"
           striped
           hover
           selectable
           select-mode="single"
           :sticky-header="headerStyle"
           show-empty
           sort-icon-left
           :sort-by.sync="sortBy"
           :sort-desc.sync="sortDesc"
           :busy="loading"
           :fields="fields"
           :items="filteredResults"
           @row-clicked="onRowClicked"
  >
    <template v-slot:head(id)="data">
      <span>{{ data.label }}<table-filter-header :value="filters.id" :show="showFilter" @input="onIdFiltered" @cleared="onIdFilterClear" @hidden="onShowFilter" @shown="onShowFilter" /></span>
    </template>

    <template v-slot:head(tenant)="data">
      <span>{{ data.label }}<table-filter-header :value="filters.tenant" :show="showFilter" @input="onTenantFiltered" @cleared="onTenantFilterClear" @hidden="onShowFilter" @shown="onShowFilter" /></span>
    </template>

    <template v-slot:head(priority)="data">
      <span>{{ data.label }}<table-filter-header :value="filters.priority" :show="showFilter" @input="onPriorityFiltered" @cleared="onPriorityFilterClear" @hidden="onShowFilter" @shown="onShowFilter" /></span>
    </template>

    <template v-slot:head(createdBy)="data">
      <span>{{ data.label }}<table-filter-header :value="filters.createdBy" :show="showFilter" @input="onCreatedByFiltered" @cleared="onCreatedByFilterClear" @hidden="onShowFilter" @shown="onShowFilter" /></span>
    </template>

    <template v-slot:head(assignedTo)="data">
      <span>{{ data.label }}<table-filter-header :value="filters.assignedTo" :show="showFilter" @input="onAssignedToFiltered" @cleared="onAssignedToFilterClear" @hidden="onShowFilter" @shown="onShowFilter" /></span>
    </template>

    <template v-slot:head(subject)="data">
      <span>{{ data.label }}<table-filter-header :value="filters.subject" :show="showFilter" @input="onSubjectFiltered" @cleared="onSubjectFilterClear" @hidden="onShowFilter" @shown="onShowFilter" /></span>
    </template>

    <template v-slot:cell(priority)="{ item }">
      <support-ticket-priority-icon :item="item" />
    </template>

    <template v-slot:cell(assignedTo)="{ item }">
      {{ item.assignedTo || 'Unassigned' }}
    </template>

    <template v-slot:cell(subject)="{ item }">
      {{ item.subject }}<span v-if="false"> &ndash; {{ item.details }}</span>
      <p class="mt-1 mb-0 text-muted text-truncate" v-if="item.latestMessage" :title="item.latestMessage">{{ item.latestMessage }}</p>
      <p class="mt-1 mb-0 text-muted text-truncate" v-else :title="item.details">{{ item.details }}</p>
    </template>

    <template v-slot:cell(status)="{ item }">
      <support-ticket-status-icon :item="item" />
    </template>

    <template v-slot:cell(actions)="{ item }">
      <b-dropdown title="Change Status" v-if="isAdministrator" :no-caret="true" variant="outline-primary" size="sm" right :popper-opts="{ modifiers: { computeStyle: { gpuAcceleration: false } } }">
        <template v-slot:button-content>
          <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
        </template>
        <b-dropdown-item v-if="item.actions.canReturnToSupport" title="Change status to support investigating" @click="placeTicketUnderInvestigation(item)">With us</b-dropdown-item>
        <b-dropdown-item v-if="item.actions.canReturnToCustomer" title="Change status to waiting for client" @click="ticketWaitingOnCustomer(item)">Waiting on client</b-dropdown-item>
        <b-dropdown-item v-if="item.actions.canPutOnHold" title="Change status to on hold" @click="placeTicketOnHold(item)">On hold</b-dropdown-item>
        <b-dropdown-item v-if="item.actions.canMoveToDevelopment" title="Change status to requires development" @click="placeTicketInDevelopment(item)">Requires development</b-dropdown-item>
        <b-dropdown-item v-if="item.actions.isReadyForTesting" title="Change status to ready for testing" @click="ticketReadyForTesting(item)">Ready for testing</b-dropdown-item>
        <b-dropdown-item v-if="item.actions.isReadyForRelease" title="Change status to ready for release" @click="ticketReadyForRelease(item)">Ready for release</b-dropdown-item>
        <b-dropdown-item v-if="item.actions.canMarkAsResolved" title="Resolved" @click="resolveTicket(item)">Resolved</b-dropdown-item>
        <b-dropdown-item v-if="item.actions.canClose" title="Close ticket" @click="closeTicket(item)">Close</b-dropdown-item>
        <b-dropdown-item v-if="item.actions.canReopen" title="Reopen ticket" @click="reopenTicket(item)">Reopen</b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { ScrollMixin } from '@/mixins/scroll-mixin';

import { namespace } from 'vuex-class';
import { BvTableFieldArray } from 'bootstrap-vue';
import { SupportTicket, Status, SupportTicketFilters } from '@/store/support/state';
import { UserResponse } from '../../api/responses/user/user-response';
import { date } from '@/filters/date';
import SupportTicketPriorityIcon from './support-ticket-priority-icon.vue';
import SupportTicketStatusIcon from './support-ticket-status-icon.vue';
import TableFilterHeader from '@/components/table/table-header-filter.vue';
import { parseFilters, applyFilters } from '@/components/table/input-filter';

const supportModule = namespace('support');
const environmentModule = namespace('environment');
const userModule = namespace('user');

@Component({
  components: {
    SupportTicketPriorityIcon,
    SupportTicketStatusIcon,
    TableFilterHeader,
  },
})
export default class DataLoadsTable extends Mixins(BvToastMixin, ScrollMixin) {
  @Prop({ type: Object, required: true }) status!: Status;
  @Prop({ type: Boolean, required: true }) loading!: boolean;
  @environmentModule.Getter('current') environment!: { environment: string };
  @supportModule.Getter tickets!: Array<SupportTicket>;
  @supportModule.Getter filters!: SupportTicketFilters;
  @userModule.Getter('current') currentUser!: UserResponse;
  @supportModule.Getter currentStatus!: Status | null;

  sortBy: string | null = null;
  sortDesc = false;
  showFilter = false;

  created() {
    this.$root.$on('filter-support-ticket-found', this.filterSupportTicketFound);
  }

  beforeDestroy() {
    this.$root.$off('filter-support-ticket-found', this.filterSupportTicketFound);
  }

  async filterSupportTicketFound(id: string | null): Promise<void> {
    if (this.currentStatus?.id === this.status.id) {
      this.$store.commit('support/setFilters', {
        assignedTo: null,
        createdBy: null,
        priority: null,
        subject: null,
        tenant: null,
        id,
      });
      this.showFilter = true;
    }
  }

  get filteredResults(): Array<SupportTicket> {
    let results = this.tickets;

    if (this.filters.id) {
      const filters = parseFilters(this.filters.id);
      results = results.filter((r) => applyFilters(r, x => x.id, filters, { mode: 'include' }));
    }

    if (this.filters.tenant) {
      const filters = parseFilters(this.filters.tenant);
      results = results.filter((r) => applyFilters(r, x => x.tenant, filters, { mode: 'include' }));
    }

    if (this.filters.priority) {
      const filters = parseFilters(this.filters.priority);
      results = results.filter((r) => applyFilters(r, x => x.priority, filters, { mode: 'include' }));
    }

    if (this.filters.createdBy) {
      const filters = parseFilters(this.filters.createdBy);
      results = results.filter((r) => applyFilters(r, x => x.createdBy, filters, { mode: 'include' }));
    }

    if (this.filters.assignedTo) {
      const filters = parseFilters(this.filters.assignedTo);
      results = results.filter((r) => applyFilters({ ...r, assignedTo: r.assignedTo === null ? 'unassigned' : r.assignedTo }, r.assignedTo === null ? x => 'unassigned' : x => x.assignedTo, filters, { mode: 'include' }));
    }

    if (this.filters.subject !== null) {
      const filters = parseFilters(this.filters.subject);
      results = results.filter((r) => applyFilters(r, x => x.subject, filters, { mode: 'include' }));
    }

    return results;
  }

  get isAdministrator(): boolean {
    return this.currentUser.roles.administrator || false;
  }

  get fields(): BvTableFieldArray {
    const fields = [
      { key: 'id', label: 'ID', class: ['col-auto', 'text-nowrap'], sortable: true },
      { key: 'priority', label: 'Priority', class: ['col-auto', 'text-nowrap'], sortable: true },
      { key: 'category', label: 'Category', class: ['col-auto', 'text-nowrap'], sortable: true },
      { key: 'issueType', label: 'Type', class: ['col-auto', 'text-nowrap'], sortable: true },
      { key: 'createdBy', label: 'Raised By', class: ['col-auto', 'text-nowrap'], sortable: true },
      { key: 'createdDate', label: 'Created', class: ['col-auto', 'text-nowrap'], sortable: true, formatter: (value: string | null) => date(value, 'dd MMM yyyy') },
      { key: 'lastUpdated', label: 'Updated', class: ['col-auto', 'text-nowrap'], sortable: true, formatter: (value: string | null) => date(value, 'dd MMM yyyy') },
      { key: 'assignedTo', label: 'Assigned To', class: ['col-auto', 'text-nowrap'], sortable: true },
      { key: 'subject', label: 'Subject', class: ['w-100', 'text-nowrap', 'text-truncate'], tdAttr: (value: any, key: string, item: any) => ({ style: 'max-width: 25rem', title: value }), sortable: false }
    ];

    if (this.status.name === 'New') {
      fields.push({ key: 'actions', label: '', class: ['col-auto'], sortable: false });
    }

    if (this.status.name === 'In Progress') {
      fields.splice(4, 0, { key: 'status', label: 'Status', class: ['col-auto', 'text-nowrap'], sortable: true });
    }

    if (this.currentUser.roles.administrator) {
      fields.splice(1, 0, { key: 'tenant', label: 'Tenant', class: ['col-auto', 'text-nowrap'], sortable: true });
    }

    return fields;
  }

  get headerStyle(): string {
    if (this.environment === null) {
      return 'calc(100vh - 230px)';
    }

    if (this.environment.environment === 'Production') {
      return 'calc(100vh - 230px)';
    }

    return 'calc(100vh - 254px)';
  }

  async onRowClicked(item: SupportTicket, index: number, evt: Event): Promise<void> {
    const sortBy = this.sortBy !== undefined ? (this.sortBy !== null ? this.sortBy : '') : '';
    const sortDesc = String(this.sortDesc);
    await this.$router.push({ name: 'support-ticket', params: { ...this.$route.params, id: item.id.toString(), sortBy: sortBy, sortDesc: sortDesc } });
  }

  onIdFiltered(value: string | null) {
    this.$store.commit('support/setFilters', { ...this.filters, id: value });
  }

  onIdFilterClear(): void {
    this.$store.commit('support/setFilters', { ...this.filters, id: null });
  }

  onTenantFiltered(value: string | null) {
    this.$store.commit('support/setFilters', { ...this.filters, tenant: value });
  }

  onTenantFilterClear(): void {
    this.$store.commit('support/setFilters', { ...this.filters, tenant: null });
  }

  onPriorityFiltered(value: string | null) {
    this.$store.commit('support/setFilters', { ...this.filters, priority: value });
  }

  onPriorityFilterClear(): void {
    this.$store.commit('support/setFilters', { ...this.filters, priority: null });
  }

  onCreatedByFiltered(value: string | null) {
    this.$store.commit('support/setFilters', { ...this.filters, createdBy: value });
  }

  onCreatedByFilterClear(): void {
    this.$store.commit('support/setFilters', { ...this.filters, createdBy: null });
  }

  onAssignedToFiltered(value: string | null) {
    this.$store.commit('support/setFilters', { ...this.filters, assignedTo: value });
  }

  onAssignedToFilterClear(): void {
    this.$store.commit('support/setFilters', { ...this.filters, assignedTo: null });
  }

  onSubjectFiltered(value: string | null) {
    this.$store.commit('support/setFilters', { ...this.filters, subject: value });
  }

  onSubjectFilterClear(): void {
    this.$store.commit('support/setFilters', { ...this.filters, subject: null });
  }

  onShowFilter(): void {
    this.showFilter = !this.showFilter;
  }

  /* Scroll Mixin overrides */
  getScrollElement(): HTMLElement {
    return this.$el as HTMLDivElement;
  }

  onScrollCompleted(): void {
    this.$emit('support-tickets-table-scroll-completed');
  }

  mounted(): void {
    if (this.$route.params.sortBy !== null && this.$route.params.sortBy !== '') {
      this.sortBy = this.$route.params.sortBy;
    }

    if (this.$route.params.sortDesc !== null) {
      this.sortDesc = this.$route.params.sortDesc !== 'false';
    }

    if (Object.values(this.filters).some(value => value !== null)) {
      this.showFilter = true;
    }
  }

  async placeTicketUnderInvestigation(ticket: SupportTicket): Promise<void> {
    try {
      await this.$store.dispatch('support/placeTicketUnderInvestigationAsync', ticket.id);

      this.showSuccessToast('Ticket successfully updated.');

      this.$emit('ticket-actioned', ticket);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  async ticketWaitingOnCustomer(ticket: SupportTicket): Promise<void> {
    try {
      await this.$store.dispatch('support/ticketWaitingOnCustomerAsync', ticket.id);

      this.showSuccessToast('Ticket successfully updated.');

      this.$emit('ticket-actioned', ticket);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  async reopenTicket(ticket: SupportTicket): Promise<void> {
    try {
      await this.$store.dispatch('support/reopenTicketAsync', ticket.id);

      this.showSuccessToast('Ticket successfully updated.');

      this.$emit('ticket-actioned', ticket);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  async resolveTicket(ticket: SupportTicket): Promise<void> {
    try {
      await this.$store.dispatch('support/resolveTicketAsync', ticket.id);

      this.showSuccessToast('Ticket successfully updated.');

      this.$emit('ticket-actioned', ticket);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  async closeTicket(ticket: SupportTicket): Promise<void> {
    try {
      await this.$store.dispatch('support/closeTicketAsync', ticket.id);

      this.showSuccessToast('Ticket successfully updated.');

      this.$emit('ticket-actioned', ticket);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  async placeTicketOnHold(ticket: SupportTicket): Promise<void> {
    try {
      await this.$store.dispatch('support/placeTicketOnHoldAsync', ticket.id);

      this.showSuccessToast('Ticket successfully updated.');

      this.$emit('ticket-actioned', ticket);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  async placeTicketInDevelopment(ticket: SupportTicket): Promise<void> {
    try {
      await this.$store.dispatch('support/placeTicketInDevelopmentAsync', ticket.id);

      this.showSuccessToast('Ticket successfully updated.');

      this.$emit('ticket-actioned', ticket);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  async ticketReadyForRelease(ticket: SupportTicket): Promise<void> {
    try {
      await this.$store.dispatch('support/ticketReadyForReleaseAsync', ticket.id);

      this.showSuccessToast('Ticket successfully updated.');

      this.$emit('ticket-actioned', ticket);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }

  async ticketReadyForTesting(ticket: SupportTicket): Promise<void> {
    try {
      await this.$store.dispatch('support/ticketReadyForTestingAsync', ticket.id);

      this.showSuccessToast('Ticket successfully updated.');

      this.$emit('ticket-actioned', ticket);
    } catch (e) {
      this.showErrorToast('Could not update ticket. Please try again.');
    }
  }
}
</script>
