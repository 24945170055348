<template>
  <b-modal id="leave-support-ticket-modal"
           title="Discard Unsent Reply?"
           :visible="visible"
           ok-variant="primary"
           ok-title="Confirm"
           cancel-variant="link"
           centered
           no-close-on-backdrop
           @ok="onOk"
           @cancel="onCancel"
           @close="onCancel"
  >
    <span>Your reply hasn't been sent. Are you sure you want to leave this page without sending it?</span>
  </b-modal>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SupportTicketLeaveConfirmationDialog extends Vue {
  visible = false;
  private resolveCallback: Function | null = null;

  show() {
    this.visible = true;
    return new Promise((resolve) => {
      this.resolveCallback = resolve;
    });
  }

  onOk() {
    this.visible = false;
    this.resolveCallback && this.resolveCallback(true);
  }

  onCancel() {
    this.visible = false;
    this.resolveCallback && this.resolveCallback(false);
  }
}
</script>
