<template>
  <b-modal id="support-ticket-no-report-id-confirmation-modal"
           title="No Report Id"
           :visible="visible"
           ok-variant="primary"
           ok-title="OK"
           cancel-variant="link"
           cancel-title="Submit without Report Id"
           centered
           no-close-on-backdrop
           @ok="onCancel"
           @cancel="onOk"
           @close="onCancel"
  >
    <span>
      No <strong>Report Id</strong> has been entered, if this ticket relates to a particular report please add the Id now.
      <br />
      This information is required for efficient support ticket triage.
      <br />
      Thank You, ON Support.
    </span>
  </b-modal>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SupportTicketNoReportIdConfirmationDialog extends Vue {
  visible = false;
  private resolveCallback: Function | null = null;

  show() {
    this.visible = true;
    return new Promise((resolve) => {
      this.resolveCallback = resolve;
    });
  }

  onOk() {
    this.visible = false;
    this.resolveCallback && this.resolveCallback(true);
  }

  onCancel() {
    this.visible = false;
    this.resolveCallback && this.resolveCallback(false);
  }
}
</script>
